<template>
  <div>
    <v-card>
      <v-card-title>
        Session Assessments Library
        <v-spacer />
        <v-btn class="mb-2" color="primary" dark @click="showEditDialog(blankSessionAssessment)">
          CREATE
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" offset-md="8">
            <v-text-field
              v-model="dtSearchQuery"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="dtHeaders"
        :items="sessionAssessments"
        :search="dtSearchQuery"
        :expanded.sync="dtExpanded"
        :loading="isLoading"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [15, 30, 45] }"
        single-expand
        show-expand
      >
        <template v-slot:item.category="{ item }">
          <v-chip>
            {{ item.category }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            @click="
              showEditDialog({
                id: item.id,
                name: item.name,
                assessmentsList: [
                  {
                    id: '',
                    uniqueKey: '',
                    sessionType: '',
                    category: '',
                    level: '',
                    frequency: '',
                    measurement: '',
                    practiceType: '',
                    goal: '',
                    sortNo: '',
                    exercisePriority: '',
                    tips: [],
                    scoringDescriptions: [],
                    exercise: null,
                    exerciseUniqueKey: '',
                  },
                ],
              })
            "
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon @click="showDeleteConfirmationDialog(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <session-assessment-details :item="item" />
          </td>
        </template>
      </v-data-table>

      <delete-dialog
        v-model="isDeleteDialogVisible"
        :item="sessionAssessment"
        :is-loading="isLoading"
        @cancel="onDeleteCanceled"
        @delete="onDeleteConfirmed"
      />

      <session-assessment-edit-dialog
        v-if="isEditDialogVisible"
        v-model="isEditDialogVisible"
        :item="sessionAssessment"
        :is-loading="isLoading"
        @cancel="onEditCanceled"
        @save="onEditSave"
      />
    </v-card>
  </div>
</template>

<script>
import {
  mdiCheck, mdiClose, mdiDeleteOutline, mdiPencilOutline,
} from '@mdi/js'
import { onMounted, ref } from 'vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import SessionAssessmentDetails from '@/views/sessionAssessments/partials/SessionAssessmentDetails.vue'
import SessionAssessmentEditDialog from '@/views/sessionAssessments/partials/SessionAssessmentEditDialog.vue'
import useSessionAssessments from '@/composables/api/assessments/session'
import useUniqueKey from '@/composables/uniqueKey'

export default {
  components: {
    DeleteDialog,
    SessionAssessmentDetails,
    SessionAssessmentEditDialog,
  },

  setup() {
    const {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,
      blankSessionAssessment,
      sessionAssessment,
      sessionAssessments,
      isLoading,
      createSessionAssessment,
      deleteSessionAssessment,
      fetchSessionAssessments,
      updateSessionAssessment,
    } = useSessionAssessments()

    const { uniqueKey } = useUniqueKey()

    const isDeleteDialogVisible = ref(false)
    const isEditDialogVisible = ref(false)

    onMounted(async () => {
      await fetchSessionAssessments()
    })

    const showDeleteConfirmationDialog = sessionAssessmentItem => {
      sessionAssessment.value = sessionAssessmentItem
      isDeleteDialogVisible.value = true
    }

    const onDeleteCanceled = () => {
      sessionAssessment.value = structuredClone(blankSessionAssessment)
      isDeleteDialogVisible.value = false
    }

    const onDeleteConfirmed = async sessionAssessmentItem => {
      await deleteSessionAssessment(sessionAssessmentItem)
      await fetchSessionAssessments()

      isDeleteDialogVisible.value = false
    }

    const showEditDialog = sessionAssessmentItem => {
      const item = structuredClone(sessionAssessmentItem)

      if (!item.uniqueKey) {
        item.uniqueKey = uniqueKey()
      }

      sessionAssessment.value = item
      isEditDialogVisible.value = true
    }

    const onEditCanceled = () => {
      sessionAssessment.value = structuredClone(blankSessionAssessment)
      isEditDialogVisible.value = false
    }

    const onEditSave = async sessionAssessmentItem => {
      try {
        if (!sessionAssessmentItem.id) {
          await createSessionAssessment(sessionAssessmentItem)
          await fetchSessionAssessments()
        } else {
          await updateSessionAssessment(sessionAssessmentItem)
          await fetchSessionAssessments()
        }

        isEditDialogVisible.value = false
      } catch (error) {
        console.error(error)
      }
    }

    return {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,

      blankSessionAssessment,
      sessionAssessments,
      sessionAssessment,

      isDeleteDialogVisible,
      showDeleteConfirmationDialog,
      onDeleteCanceled,
      onDeleteConfirmed,

      isEditDialogVisible,
      showEditDialog,
      onEditCanceled,
      onEditSave,

      isLoading,

      icons: {
        mdiCheck,
        mdiClose,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>


<template>
  <v-dialog :value="value" max-width="1000" scrollable persistent @input="$emit('input', $event)">
    <v-card>
      <v-card-title>{{ formTitle }}</v-card-title>
      <v-card-text>
        <ValidationObserver ref="formObserver">
        <v-form :disabled="isLoading">
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">
                <ValidationProvider name="Id">
                <template v-slot="{ errors }">
                  <v-text-field v-model="item.id" label="Id" :error-messages="errors" disabled />
                </template>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="4">
                <ValidationProvider name="Name" rules="required|max:128">
                <template v-slot="{ errors }">
                  <v-text-field
                      v-model="item.name"
                      label="Name*"
                      :error-messages="errors"
                      />
                </template>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="4">
                <ValidationProvider name="Category" rules="required">
                <template v-slot="{ errors }">
                  <v-select
                      v-model="item.category"
                      :items="sessionCategoryOptions"
                      label="Category*"
                      :error-messages="errors"
                      />
                </template>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row v-for="(session, index) in item.sessions" :key="`session-${index}`">
              <v-col cols="12">
                <v-card>
                  <v-card-title>
                    Session {{ index + 1 }}
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <ValidationProvider :name="`Session Type ${index + 1}`" rules="required">
                        <template v-slot="{ errors }">
                          <v-select
                              v-model="session.type"
                              :items="sessionTypeOptions"
                              label="Session Type*"
                              :error-messages="errors"
                              @change="filterAssessments(index)"
                              />
                        </template>
                        </ValidationProvider>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <ValidationProvider :name="`Assessments ${index + 1}`" rules="required">
                        <template v-slot="{ errors }">
                          <v-autocomplete
                              v-model="session.assessments"
                              label="Add Assessments*"
                              :items="filteredAssessments[index]"
                              item-text="titleOfSession"
                              item-value="id"
                              return-object
                              multiple
                              clearable
                              chips
                              :error-messages="errors"
                              :disabled="!session.type"
                              >
                              <template v-slot:selection="data">
                                <v-chip
                                    close
                                    small
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    @click="data.select"
                                    @click:close="removeSelectedAssessment(index, data.item)"
                                    >
                                    {{ data.item.titleOfSession }} - {{ data.item.sessionType }} - {{ data.item.category }} - {{ data.item.level }} - {{ data.item.measurement }}
                                </v-chip>
                              </template>
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.titleOfSession }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-chip small>{{ data.item.sessionType }}</v-chip>
                                  <v-chip small>{{ data.item.category }}</v-chip>
                                  <v-chip small>{{ data.item.level }}</v-chip>
                                  <v-chip small>{{ data.item.measurement }}</v-chip>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </template>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn text color="secondary" @click="addSession">Add Session</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onCancel">Cancel</v-btn>
        <v-btn :loading="isLoading" color="primary" @click="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import useGolfAssessments from '@/composables/api/assessments/golf'
  import useFitnessAssessments from '@/composables/api/assessments/fitness'
  import { SessionCategory, SessionType } from '@/enums/enums'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      value: Boolean,
      item: {
        type: Object,
        default: () => ({
          id: '',
          name: '',
          category: '',
          sessions: [],
        }),
      },
      formTitle: String,
      isLoading: Boolean,
      icons: Object,
    },
    data() {
      return {
        sessionTypeOptions: Object.values(SessionType),
        sessionCategoryOptions: Object.values(SessionCategory).filter(
          (category) => category !== "play"
        ),
        allAssessments: [],
        filteredAssessments: [],
        overallAssessmentError: '',
      }
    },
    async created() {
      const { fetchGolfAssessments, golfAssessments } = useGolfAssessments()
      const { fetchFitnessAssessments, fitnessAssessments } = useFitnessAssessments()

      await fetchGolfAssessments()
      await fetchFitnessAssessments()

      this.allAssessments = [...golfAssessments.value, ...fitnessAssessments.value]
    },
    methods: {
      filterAssessments(index) {
        const session = this.item.sessions[index]
        const { type } = session

        const assessments = this.allAssessments.filter(a => {
          const assessmentType = a.sessionType || a.type || ''
          const assessmentTypeNormalized = assessmentType.toString().toLowerCase()
          const typeNormalized = type.toString().toLowerCase()
          return assessmentTypeNormalized === typeNormalized
        })

        this.$set(this.filteredAssessments, index, assessments)
      },
      removeSelectedAssessment(index, item) {
        const session = this.item.sessions[index]
        session.assessments = session.assessments.filter(a => a.id !== item.id)
      },
      addSession() {
        this.item.sessions.push({
          type: '',
          assessments: [],
        })
        this.filteredAssessments.push([])
      },

      async onSave() {
        this.overallAssessmentError = ''

        const isValid = await this.$refs.formObserver.validate()

        if (isValid) {
          if (this.item.sessions.every(session => session.assessments && session.assessments.length > 0)) {
            const saveData = {
              name: this.item.name,
              category: this.item.category,
              sessions: this.item.sessions.map(session => ({
                type: session.type,
                category: this.item.category,
                assessments: session.assessments
                .filter(a => a.id && a.id !== '')
                .map(a => ({
                  id: a.id,
                  titleOfSession: a.titleOfSession,
                  uniqueKey: a.uniqueKey,
                  sessionType: a.sessionType,
                  category: a.category,
                  level: a.level,
                  frequency: a.frequency,
                  measurement: a.measurement,
                  practiceType: a.practiceType,
                  goal: a.goal,
                  sortNo: a.sortNo,
                  exercisePriority: a.exercisePriority,
                  tips: a.tips,
                  scoringDescriptions: a.scoringDescriptions,
                  exercise: a.exercise,
                  exerciseUniqueKey: a.exerciseUniqueKey,
                })),
              })),
            }
            if (this.item.id !== '') {
              saveData.id = this.item.id
            }
            this.$emit('save', saveData)
          } else {
            this.overallAssessmentError = 'At least one assessment must be selected in each session.'
          }
        }
      },
      onCancel() {
        this.$emit('cancel')
      },
    },
  }
</script>
